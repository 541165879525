import { HttpErrorResponse, HttpEvent, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { catchError, tap, throwError } from 'rxjs';
import { RESPONSE_CODES } from '../../shared/models/enum';
import { AuthenticationService } from '../services/authentication.service';
import { inject } from '@angular/core';
import { TOAST_TYPE, ToastService } from '../../shared/components/toast/toast.service';

export const errorInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next) => {
    const authenticationService = inject(AuthenticationService);
    const toastService = inject(ToastService);
    return next(req).pipe(tap((res: HttpEvent<any>) => {
        if (res instanceof HttpResponse) {
            if (!res.body.success && res.body.code === RESPONSE_CODES.ERROR) {
                toastService.showToast('ph-fill ph-warning', res.body.message, TOAST_TYPE.ERROR);
            }
            if (!res.body.success && res.body.code === RESPONSE_CODES.UNAUTHORIZED) {
                toastService.showToast('ph-fill ph-warning', 'Unauthorized!', TOAST_TYPE.ERROR);
                authenticationService.logOutUser();
                // should be session expired as of now unauthorized is being used to logout user as per backend
            }
        }
    }),
        catchError((err: any) => {
            if (err instanceof HttpErrorResponse && navigator.onLine) {
                toastService.showToast('ph-fill ph-warning', err.message, TOAST_TYPE.ERROR);
            }
            return throwError(() => err);
        })
    );
}