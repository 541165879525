import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { inject } from '@angular/core';

export const loggedInGuard: CanActivateFn = () => {
  const authenticationService = inject(AuthenticationService);
  const router = inject(Router);

  if (authenticationService.currentUser.value?.project && authenticationService.currentUser.value.id) {
    router.navigate(['/dashboard'])
    return false;
  } else {
    return true;
  }

};