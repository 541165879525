import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, signal } from '@angular/core';
import { ToastPackage } from 'ngx-toastr';
import { TOAST_TYPE } from './toast.service';


export interface ToastProps {
  icon: string,
  message: string,
  toastType: TOAST_TYPE
}

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ToastComponent implements OnInit, OnDestroy {

  props = signal<ToastProps | null>(null);
  toastTimer !: ReturnType<typeof setTimeout>;

  constructor(private toastPackage: ToastPackage) { }

  ngOnInit() {
    this.props.set(this.toastPackage.config.payload);

    this.toastTimer = setTimeout(() => {
      this.toastPackage.toastRef.close();
    }, this.toastPackage.config.timeOut)
  }

  clearToast() {
    this.toastPackage.toastRef.close();
  }

  ngOnDestroy(): void { 
    clearTimeout(this.toastTimer);
  }

}